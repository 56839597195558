<template>
  <div ref="IntercityBusSettle" class="IntercityBusSettle">
    <div ref="functionButtons" class="function-buttons"></div>
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="发车时间段：" class="code-time-range">
          <el-date-picker
            v-model="time"
            range-separator="至"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="selectTime"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="所属公司：">
          <companySelect
            :clearable="true"
            :company-tree="companyList"
            :valuename="valuename"
            :value="value"
            @getValue="getGroupId"
          ></companySelect>
        </el-form-item>
        <el-form-item label="车牌号：">
          <el-select
            v-model="form.cph"
            filterable
            remote
            reserve-keyword
            clearable
            placeholder="请输入车牌号"
            :remote-method="queryCph"
            @clear="form.cph = null"
          >
            <el-option
              v-for="item in cphList"
              :key="item.licensePlateNumber"
              :label="item.licensePlateNumber"
              :value="item.licensePlateNumber"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="含无效乘客：">
          <el-select
            v-model="form.invalidPassenger"
            clearable
            placeholder="请选择状态"
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="电召订票收现：">
          <el-select v-model="form.includingCallOrder">
            <el-option label="包含" :value="true"></el-option>
            <el-option label="不包含" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="queryFun"
            >查询</el-button
          >
          <el-button type="primary" size="small" @click="exportFun"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :col-class-type="true"
      :table-height="TableHeight"
      :operation="true"
    >
      <template slot-scope="{ scopeRow }">
        <el-button
          type="text"
          size="small"
          sort="primary"
          @click="referFun(scopeRow)"
          >查看</el-button
        >
      </template>
    </Table>
    <div class="pagination">
      <el-pagination
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      >
      </el-pagination>
    </div>
    <charter-popup
      v-if="show"
      header-text="单车结算明细"
      max-height="700px"
      width="900"
      confirm-text="导出"
      @closePopupFun="show = false"
      @confirmFun="confirmBtn"
    >
      <div class="content">
        <div class="form">
          <div class="item">车牌号：{{ detial.cph }}</div>
          <div class="item">
            结算时间：{{ detial.startTime + " - " + detial.endTime }}
          </div>
          <div class="item">购票金额：{{ detial.sumMoney }}元</div>
          <div class="item">实载率：{{ detial.sumRealLoadRateTotal }}</div>
        </div>
        <Table :table-data="tableData2" :title-name="titleName2"></Table>
      </div>
    </charter-popup>
  </div>
</template>

<script>
import { lastTime } from "@/tools/getTime.js";
import {
  getCompanyTree,
  singleVehicleExport,
  singleVehiclePage,
  getVehicleList,
  singleVehicleDetailInfo,
  singleVehicleDetailExport,
} from "@/api/lib/api.js";
import companySelect from "@/components/treeSelect/companySelect.vue";
import moment from "moment";
export default {
  components: {
    companySelect,
  },
  data() {
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        startTime: null,
        endTime: null,
        companyIds: [],
        cph: null,
        invalidPassenger: true,
        includingCallOrder: false,
      },
      time: null,
      exportForm: {},
      value: null,
      valuename: null,
      companyList: [],
      carNumber: [],
      TableHeight: 0,
      statusList: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      tableData: [],
      titleName: [
        {
          title: " 结算时间段",
          props: "settlementTime",
        },
        {
          title: "公司",
          props: "companyName",
        },
        {
          title: "车牌号",
          props: "cph",
        },
        {
          title: "乘客数量",
          props: "passengersCount",
        },
        {
          title: "购票金额",
          props: "money",
        },
      ],
      total: 0,
      show: false,
      titleName2: [
        {
          title: "车牌号",
          props: "cph",
        },
        {
          title: "驾驶员",
          props: "driverName",
        },
        {
          title: "线路",
          props: "lineName",
          width: 120,
        },
        {
          title: "座位数",
          props: "ticketNum",
        },
        {
          title: "班次时间",
          props: "departureDateTime",
          width: 170,
        },
        {
          title: "乘客数量",
          props: "passengersCount",
        },
        {
          title: "实载率",
          props: "realLoadRateTotal",
          SpecialJudgment: (res) => (res * 10000) / 100 + "%",
        },
        {
          title: "购票金额",
          props: "money",
        },
      ],
      tableData2: [],
      vehicleId: null,
      detial: {
        sumMoney: 0,
        sumRealLoadRateTotal: 0,
        startTime: "",
        cph: "",
        endTime: "",
      },
      cphList: [],
    };
  },
  mounted() {
    const date = lastTime(new Date()).slice(0, 7);
    const day = moment().format("YYYY-MM") + "-25";
    this.time = [date + "-26", day];
    this.queryFun();
    this.getCompanyList();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  methods: {
    selectTime(val) {
      this.form.startTime = val[0] + " 00:00:00";
      this.form.endTime = val[1] + " 23:59:59";
    },
    queryFun() {
      this.form.startTime = this.time[0] + " 00:00:00";
      this.form.endTime = this.time[1] + " 23:59:59";
      this.exportForm = this.deepClone(this.form);
      this.renderData();
    },
    exportFun() {
      singleVehicleExport(this.exportForm).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName =
          "城际专车单车结算 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击=
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    renderData() {
      singleVehiclePage(this.form).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    getGroupId(v) {
      if (v == null) {
        this.form.companyIds = [];
      } else {
        this.form.companyIds = [v];
      }
    },
    computeHeight() {
      const wholeHeight = this.$refs.IntercityBusSettle.clientHeight;
      this.TableHeight = wholeHeight - 270 + "px";
    },
    // 分页页数发生变化
    onCurrentChange(val) {
      this.form.currentPage = val;
      this.renderData();
    },
    // 分页change方法
    onSizeChange(val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      this.renderData();
    },
    // 车牌号模糊查询
    queryCph(query) {
      this.cphList = [];
      if (query !== "") {
        setTimeout(() => {
          getVehicleList({
            licensePlateNumber: query,
            currentPage: 1,
            pageSize: 20,
          }).then((res) => {
            if (res.code === "SUCCESS") {
              this.cphList = res.data.list;
            }
          });
        }, 200);
      } else {
        this.cphList = [];
      }
    },
    referFun(item) {
      this.show = true;
      this.vehicleId = item.vehicleId;
      const data = {
        startTime: this.form.startTime,
        endTime: this.form.endTime,
        vehicleId: item.vehicleId,
        includingCallOrder: this.form.includingCallOrder,
        invalidPassenger: this.form.invalidPassenger,
      };
      singleVehicleDetailInfo(data).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData2 = res.data.singleVehicleDetails || [];
          this.detial.sumMoney = res.data.sumMoney;
          this.detial.cph = res.data.cph;
          this.detial.startTime = res.data.startTime.slice(0, 10);
          this.detial.endTime = res.data.endTime.slice(0, 10);
          const total = res.data.sumRealLoadRateTotal * 100;
          this.detial.sumRealLoadRateTotal = total.toFixed(2) + "%";
        }
      });
    },
    confirmBtn() {
      const data = {
        startTime: this.form.startTime,
        endTime: this.form.endTime,
        vehicleId: this.vehicleId,
        includingCallOrder: this.form.includingCallOrder,
        invalidPassenger: this.form.invalidPassenger,
      };
      singleVehicleDetailExport(data).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName =
          "城际专车单车结算统计 " +
          this.detial.cph +
          this.GMTToStr(new Date()) +
          ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击=
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.IntercityBusSettle {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 4px 20px;
  box-sizing: border-box;
  background: #ffffff;
  .code-time-range {
    .el-date-editor {
      width: 13vw !important;
      ::v-deep .el-range-separator {
        line-height: 24px;
      }
    }
  }
}
.Table {
  margin-top: 16px;
}
.content {
  padding: 0 20px 20px 20px;
  .form {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    font-size: 15px;
  }
}
</style>
